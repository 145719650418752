@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aref+Ruqaa:wght@700&family=Readex+Pro:wght@500&display=swap');


*{
  scroll-behavior: smooth;
  font-family: 'Readex Pro', sans-serif;
}
body {
  background-color: #f1f1f1;
  color: #1b1b1b;
  text-align: right;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#textAnim{
  transform: translateX(-100%);
  animation: move 20s linear infinite;
}

#quds{
  font-family: 'Readex Pro', sans-serif;
}

@keyframes move{
  to{transform: translateX(100%);}
}
.pagination{
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-top: 80px;
  margin-bottom: 80px;
  font-size: 1rem;
  font-weight: bold;
}
.page{
  margin-left: 5px;
  margin-right: 5px;
  transition-duration: 200ms;
  cursor: pointer;
}
.page:hover{
  color: rgb(234 179 8 / 1);
}
.next-prev{
  color: rgb(1, 70, 1);
  padding: 2px 5px 2px 5px;
  text-align: center;
  transition-duration: 200ms;
  margin-left: 10px;
  margin-right: 10px;
}
.next-prev:hover{
  color: rgb(234 179 8 / 1);
}
.page-active{
  color: red;
}